import React from 'react'
import {
  SquareContainer,
  StyledText,
  StyledTextError
} from './styledComponents'
import { Col, Row, Tooltip } from 'antd'
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  FormOutlined
} from '@ant-design/icons'
import { StyledButtonPrimary } from '../Shared/Button/styledComponent'

type props = {
  status?: number
  name: string
  id?: string
  btnOnClick: any
  requirementsNotMet: boolean
}

const CardComponent: React.FC<props> = ({
  status,
  name,
  btnOnClick,
  id,
  requirementsNotMet
}) => {
  const componentId: string = id ?? 'CardComponentId'

  return (
    <SquareContainer
      style={{ borderColor: status === 3 ? '#FF8C07' : '#fff' }}
      data-testid="status3"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col
          xs={status === 3 || status === 2 ? 16 : 10}
          sm={12}
          md={12}
          lg={16}
          flex="start"
        >
          <Row justify="start" align="middle">
            {(status === 2 || status === 3) && (
              <>
                <StyledTextError>
                  {status === 2 ? 'Pendiente' : 'Rechazado'}
                </StyledTextError>
                <Tooltip title={status === 2 ? 'Pendiente' : 'Rechazado'}>
                  <ExclamationCircleOutlined
                    style={{
                      fontSize: '30px',
                      color: '#FF8C07',
                      marginRight: '10px'
                    }}
                    data-testid="status2"
                  />
                </Tooltip>
              </>
            )}
            <StyledText>{name}</StyledText>
          </Row>
        </Col>
        {status === 0 && (
          <Col xs={14} sm={12} md={10} lg={6} flex="end">
            <StyledButtonPrimary
              onClick={btnOnClick}
              disabled={requirementsNotMet}
              id={componentId}
            >
              Registrar
            </StyledButtonPrimary>
          </Col>
        )}
        {status !== 0 && (
          <Col xs={status === 3 ? 8 : 14} sm={12} md={10} lg={6} flex="end">
            <Row justify="space-evenly" align="middle">
              {status === 1 && (
                <Tooltip title="VINCULACIÓN EXITOSA">
                  <CheckCircleFilled
                    style={{
                      fontSize: '25px',
                      color: '#009632'
                    }}
                    data-testid="status1"
                  />
                </Tooltip>
              )}
              {status === 3 && (
                <FormOutlined
                  style={{ fontSize: '25px' }}
                  onClick={btnOnClick}
                  data-testid="status3-edit"
                />
              )}
            </Row>
          </Col>
        )}
      </Row>
    </SquareContainer>
  )
}

export default CardComponent
