// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import InteractionContainer from '../../containers/InteractionContainer'
import LayoutMain from '../../layout'

const Interaction: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Integraciones" />
      <WithPrivateRoute component={InteractionContainer} />
    </LayoutMain>
  )
}

export default Interaction
