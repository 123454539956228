import { gql } from '@apollo/client'

export const GET_PAYMENT_AFFILIATIONS = gql`
  query GetPaymentAffiliations {
    getPaymentAffiliations {
      DeviceID
      PartnerID
      PaymentProviderID
      StatusProcess
      MessageProcess
      StatusID
      CreatedAt
      LastUpdatedAt
      CreatedBy
      LastUpdatedBy
    }
  }
`

export const GET_PAYMENT_STATUS_AFFILIATION = gql`
  query GetPaymentAffiliationStatus {
    getPaymentAffiliationStatus {
      StatusID
      PaymentProviderID
      Name
      MessageProcess
      Message
    }
  }
`

export const GET_PAYMENT_AFFILIATION_PROVIDER = gql`
  query GetPaymentProviders {
    getPaymentProviders {
      PaymentProviderID
      Name
      Description
      StatusID
      ImgUrl
      Type
      CreatedAt
      LastUpdatedAt
      CreatedBy
      LastUpdatedBy
    }
  }
`
