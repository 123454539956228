import styled from 'styled-components'
import { Col } from 'antd'

export const InteractionContent = styled(Col)`
  height: 100vh;
  margin: 2%;

  h1 {
    font-size: 18px;
    padding-top: 20px;
    font-weight: 600px;
    color: #0b4793;
  }

  @media all and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;

    h2 {
      padding-top: 10px;
    }
  }
`

export const StyledSubtitle = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
`
