import React, { useState, useEffect } from 'react'
import { Row, Form, Col } from 'antd'
import { useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import moment from 'moment'
import {
  GET_PAYMENT_STATUS_AFFILIATION,
  GET_PAYMENT_AFFILIATION_PROVIDER
} from '../../graphql/queries/paymentAffiliation'
import CardComponent from '../../components/Cards'
import Loading from '../../components/Shared/Loading'
import { InteractionContent, StyledSubtitle } from './styledcomponents'
import IntegrationTypes from '../../types/IntegrationTypes'
import UserTypes from '../../types/UserTypes'
import { GET_PROFILE } from '../../graphql/queries/profile'
import ModalResponse from '../../components/Shared/ModalResponse'
import ModalResult from '../../components/Shared/ModalResult'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/storage'

type propsResult = {
  isModalVisible: boolean
  content: any
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  content: <></>
}

const InteractionContainer: React.FC = () => {
  const noCache: any = { fetchPolicy: 'no-cache' }
  const [form] = Form.useForm()
  const [result, setResult] = useState(resultInitialState)

  const { data: paymentAffiliationStatusData, loading: affStatusLoading } =
    useQuery<IntegrationTypes.PaymentAffiliationStatus>(
      GET_PAYMENT_STATUS_AFFILIATION,
      noCache
    )

  const { data: paymentProvidersData, loading: providersLoading } =
    useQuery<IntegrationTypes.PaymentProviders>(
      GET_PAYMENT_AFFILIATION_PROVIDER,
      noCache
    )

  const yoloPagoProviderID = paymentProvidersData?.getPaymentProviders.find(
    (provider: any) => provider.Description === 'YoLoPago'
  )?.PaymentProviderID

  const yoloPagoAffiliationStatus =
    paymentAffiliationStatusData?.getPaymentAffiliationStatus.find(
      (provider: any) => provider.PaymentProviderID == yoloPagoProviderID
    )?.StatusID || 0

  const yoloPagoAffiliation =
    paymentAffiliationStatusData?.getPaymentAffiliationStatus.find(
      (provider: any) => provider.PaymentProviderID == yoloPagoProviderID
    )

  const parseYoloPagoAffiliation = {
    ...yoloPagoAffiliation,
    MessageProcess: yoloPagoAffiliation?.MessageProcess
      ? JSON.parse(yoloPagoAffiliation?.MessageProcess)
      : { message: '' }
  }

  const { data: userData } = useQuery<UserTypes.Partner>(GET_PROFILE, noCache)

  const cardRequirementsNotMet = () => {
    const storeExist = userData ? userData.getProfile.stores : false
    const deviceExist =
      userData && storeExist ? userData.getProfile.stores[0].device : false

    return !storeExist || !deviceExist
  }

  useEffect(() => {
    if (
      paymentAffiliationStatusData &&
      paymentProvidersData &&
      yoloPagoAffiliationStatus === 1
    ) {
      const showYPDash = getLocalStorageItem('showYPInt')
      const dateYPApprove = moment(
        parseYoloPagoAffiliation.MessageProcess?.date,
        'DD-MM-YYYY hh:mm:ss'
      )
        .add(1, 'days')
        .valueOf()
      const currentDate = moment().valueOf()
      if (showYPDash === null && currentDate < dateYPApprove) {
        setLocalStorageItem('showYPInt', 'false')
        setResult({
          isModalVisible: true,
          content: (
            <ModalResult
              key={`success-${Math.random()}`}
              status={'success'}
              subTitle={
                <>
                  <StyledSubtitle style={{ color: '#52c41a' }}>
                    Tu registro ha sido aceptado
                  </StyledSubtitle>
                  <StyledSubtitle style={{ color: 'black' }}>
                    Podras comenzar a cobrar con tarjeta dentro de las próximas
                    24 a 48 horas. Si tienes algún problema, comunícate con
                    soporte al WhatsApp 5535784444
                  </StyledSubtitle>
                </>
              }
              extra={
                <StyledButtonPrimary
                  type="button"
                  onClick={handleCancel}
                  key={'Accept-create'}
                >
                  Aceptar
                </StyledButtonPrimary>
              }
            />
          )
        })
      }
    }
  }, [yoloPagoAffiliationStatus])

  const handleCancel = () => {
    setResult(resultInitialState)
  }

  if (providersLoading || affStatusLoading) return <Loading />

  return (
    <InteractionContent>
      <h1>Proveedores pendientes</h1>
      <Form form={form} name="yoloPago" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            {yoloPagoAffiliationStatus !== 1 && (
              <CardComponent
                name="yolopago"
                requirementsNotMet={cardRequirementsNotMet()}
                status={yoloPagoAffiliationStatus}
                btnOnClick={() => navigate('/interaction/yolopago')}
              />
            )}
          </Col>
        </Row>
      </Form>
      <h1>Tus Integraciones</h1>
      <Form form={form} name="yoloPago" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            {yoloPagoAffiliationStatus === 1 && (
              <CardComponent
                name="yolopago"
                requirementsNotMet={cardRequirementsNotMet()}
                status={yoloPagoAffiliationStatus}
                btnOnClick={() => navigate('/interaction/yolopago')}
              />
            )}
          </Col>
        </Row>
      </Form>
      <ModalResponse
        isModalVisible={result.isModalVisible}
        content={result.content}
        closable={false}
        onCancel={handleCancel}
      />
    </InteractionContent>
  )
}

export default InteractionContainer
