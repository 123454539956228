import styled from 'styled-components'

export const SquareContainer = styled.div`
  width: 100%;
  margin: 18px 0 16px;
  padding: 12px 24px 11px 25px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: solid;
  border-width: 1px;

  @media all and (max-width: 767px) {
    padding: 6px 2px 6px 2px;
  }
`

export const StyledText = styled.span`
  width: 40px;
  height: 18px;
  margin: 5px 12px 5px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a1a1a;
  text-transform: uppercase;
`

export const StyledTextError = styled.span`
  width: 40px;
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff8c07;
  text-transform: uppercase;
  margin-right: 57px;
`
